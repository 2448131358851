// Main Modules
import {AppRoutingModule} from './app-routing.module';
import {ErrorHandler, Injector, NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClient, HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {MatGridListModule} from '@angular/material/grid-list';

import {AppComponent} from './app.component';
// Services
import {SERVICES} from './core/services/services';
import {GlobalExceptionHandlerService} from './core/services/global-exception-handler/global-exception-handler.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatDialogModule} from '@angular/material/dialog';
import {DefaultUrlSerializer, UrlSerializer, UrlTree} from '@angular/router';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {registerLocaleData} from '@angular/common';
import { MassRescheduleDialogComponent } from './shared/modals/mass-reschedule-dialog/mass-reschedule-dialog.component';
// Locales
import localeEs from '@angular/common/locales/es';
import localeKo from '@angular/common/locales/ko';
import localeVi from '@angular/common/locales/vi';
import localeZh from '@angular/common/locales/zh';
import { ToastrModule } from 'ngx-toastr';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MassRescheduleResponsDialogComponent } from './shared/modals/mass-reschedule-respons-dialog/mass-reschedule-respons-dialog.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HttpInterceptorService } from './core/services/Interceptor/Interceptor-global-error-handler.service';
import { HeaderModifierInterceptorService } from './core/services/Interceptor/header-modifier-interceptor.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { APP_INITIALIZER } from '@angular/core';
import * as Sentry from "@sentry/angular-ivy";
import { Router } from "@angular/router";


registerLocaleData(localeEs, 'es');
registerLocaleData(localeKo, 'ko');
registerLocaleData(localeVi, 'vi');
registerLocaleData(localeZh, 'zh-CN');
registerLocaleData(localeZh, 'zh-TW');

export let AppInjector: Injector;

// TODO for using sign '+' in patient screening invitation token
export default class CustomUrlSerializer implements UrlSerializer {
  private _defaultUrlSerializer: DefaultUrlSerializer = new DefaultUrlSerializer();

  parse(url: string): UrlTree {
    // Encode "+" to "%2B"
    url = url.replace(/\+/gi, '%2B');
    // Use the default serializer.
    return this._defaultUrlSerializer.parse(url);
  }

  serialize(tree: UrlTree): string {
    return this._defaultUrlSerializer.serialize(tree).replace(/\+/gi, '%2B');
  }
}


@NgModule({
  declarations: [AppComponent,MassRescheduleDialogComponent,MassRescheduleResponsDialogComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatGridListModule,
    ToastrModule,
    ToastrModule.forRoot(),
    MatAutocompleteModule,
    MatButtonModule,
    MatIconModule,
    MatBadgeModule,
    MatMenuModule,
    MatInputModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSidenavModule,
    MatSelectModule,
    MatTabsModule,
    MatChipsModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
          return new TranslateHttpLoader(http, './assets/i18n/', '.json?cacheBuster=' + new Date().getTime());
        },
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    SERVICES,
    GlobalExceptionHandlerService,
    {provide: ErrorHandler, useClass: GlobalExceptionHandlerService},
    {provide: UrlSerializer, useClass: CustomUrlSerializer},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderModifierInterceptorService,
      multi: true
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private injector: Injector) {
    AppInjector = this.injector;
  }
}
