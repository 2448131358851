import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {UserSessionService} from '../user-session/user-session.service';
import {LanguageService} from '../language/language.service';
import {DateUtility} from '../../utils/date-utility';
import {environment} from '../../../../environments/environment';
import {catchError} from 'rxjs/operators';
import {of as observableOf} from 'rxjs';

const apiUrl = environment.apiUrl;

interface NetworkOptions {
  headers?: {[key: string]: any} | null;
  params?: HttpParams | null;
  responseType?: any;
  role?: string;
}

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  // profilePictureCache: Map<UUID, string>;
  constructor(
    private http: HttpClient,
    private session: UserSessionService,
    private languageService: LanguageService
  ) {}

  static handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else if (error.status >= 400) {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: `);
      console.error(error.error);
      return throwError(error);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }

  public fetchResource<T>(url: string, options: NetworkOptions = {}): Observable<T> {
    const {headers = null, params = null, ...rest} = options;
    const defaultHeaders: HttpHeaders = new HttpHeaders({
      'Accept-Language': this.languageService.getCurrLanguage(),
      'X-TimeZone': DateUtility.prototype.getTimeZone(),
      'X-Role': 'doctor',
    });

    return this.http
      .get<T>(apiUrl + url, {
        params,
        headers: updateHeaders(this.addToken(defaultHeaders), headers),
        ...rest,
      })
      .pipe(catchError(NetworkService.handleError));
  }

  // TODO: Needs body parameter
  public postResource<T>(url: string, body: any, options: NetworkOptions = {}): Observable<T> {
    const {headers = null, params = null, ...rest} = options;
    const defaultHeaders: HttpHeaders = new HttpHeaders({
      'Accept-Language': this.languageService.getCurrLanguage(),
      'X-TimeZone': DateUtility.prototype.getTimeZone(),
      'X-Role': 'doctor', // TODO: Dynamically populate X-Role field with type
    });

    return this.http
      .post<T>(apiUrl + url, body, {headers: updateHeaders(this.addToken(defaultHeaders), headers), params, ...rest})
      .pipe(catchError(NetworkService.handleError));
  }

  // TODO: Needs body parameter
  public patchResource<T>(url: string, body: any, options: NetworkOptions = {}): Observable<T> {
    const {headers = null, params = null, ...rest} = options;
    const defaultHeaders = {
      Authorization: 'Bearer ' + this.session.token.access_token,
      'Accept-Language': this.languageService.getCurrLanguage(),
      'X-TimeZone': DateUtility.prototype.getTimeZone(),
      'X-Role': 'doctor', // TODO: Dynamically populate X-Role field with type
    };
    const mergedHeaders = headers ? {...defaultHeaders, ...headers} : defaultHeaders;

    return this.http
      .patch<T>(apiUrl + url, body, {headers: new HttpHeaders(mergedHeaders), params, ...rest})
      .pipe(catchError(NetworkService.handleError));
  }

  // TODO: Needs body parameter
  public putResource<T>(url: string, body: any, options: NetworkOptions = {}): Observable<T> {
    const {headers = null, params = null, ...rest} = options;
    const defaultHeaders: HttpHeaders = new HttpHeaders({
      'Accept-Language': this.languageService.getCurrLanguage(),
      'X-TimeZone': DateUtility.prototype.getTimeZone(),
      'X-Role': 'doctor', // TODO: Dynamically populate X-Role field with type
    });

    return this.http
      .put<T>(apiUrl + url, body, {headers: updateHeaders(this.addToken(defaultHeaders), headers), params, ...rest})
      .pipe(catchError(NetworkService.handleError));
  }

  public deleteResource<T>(url: string, options: NetworkOptions = {}): Observable<T> {
    const {headers = null, params = null, ...rest} = options;
    const defaultHeaders: HttpHeaders = new HttpHeaders({
      'Accept-Language': this.languageService.getCurrLanguage(),
      'X-TimeZone': DateUtility.prototype.getTimeZone(),
      'X-Role': 'doctor', // TODO: Dynamically populate X-Role field with type
    });

    return this.http
      .delete<T>(apiUrl + url, {headers: updateHeaders(this.addToken(defaultHeaders), headers), params, ...rest})
      .pipe(catchError(NetworkService.handleError));
  }

  public getImage(url: string): Observable<Blob | string> {
    if (!this.session.token) return observableOf('');
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.session.token.access_token,
      'Accept-Language': this.languageService.getCurrLanguage(),
      'X-TimeZone': DateUtility.prototype.getTimeZone(),
      'X-Role': 'doctor', // TODO: Dynamically populate X-Role field with type
    });
    return this.http
      .get(apiUrl + url, {headers: headers, responseType: 'blob'})
      .pipe(catchError(NetworkService.handleError));
  }

  public getImageWithoutCredential(url: string): Observable<Blob> {
    return this.http.get(apiUrl + url, {responseType: 'blob'}).pipe(catchError(NetworkService.handleError));
  }

  addToken(headers: HttpHeaders): HttpHeaders {
    let defaultHeaders = headers;

    if (this.session.loggedInStatus || (headers && headers['X-Role'])) {
      const accessToken = this.getAuthToken();
      if (accessToken) {
        defaultHeaders = defaultHeaders.set('Authorization', 'Bearer ' + accessToken);
      }
    }
    return defaultHeaders;
  }

  getAuthToken(){
    const keycloak = JSON.parse(localStorage.getItem('keycloak'));
    return keycloak
    ? keycloak.access_token
    : this.session && this.session.token
    ? this.session.token.access_token
    : null;
  }

}

function updateHeaders(defaultHeaders: HttpHeaders, headers: {[key: string]: any}): HttpHeaders {
  for (const key in headers) {
    if (headers.hasOwnProperty(key)) {
      defaultHeaders = defaultHeaders.set(key, headers[key]);
    }
  }

  return defaultHeaders;
}
