import {Injectable} from '@angular/core';
import {AuthToken} from '../../models/auth-token.model';
import {MatDrawer} from '@angular/material/sidenav';
import {BehaviorSubject} from 'rxjs';
import config from '../../../../config';
import {MobileApiUserSession, UserSession} from '../../models/user-session.model';

@Injectable({
  providedIn: 'root',
})
export class UserSessionService {
  private _sessionAccount = new BehaviorSubject<UserSession>(null);
  private _patientCarePlan = new BehaviorSubject<any>(null);
  private _mobileSessionAccount = new BehaviorSubject<MobileApiUserSession>(null);

  public sessionAccount$ = this._sessionAccount.asObservable();

  chart: any;

  public token: AuthToken;
  public sessionAccount: UserSession;
  public loggedInStatus: boolean;
  public drawer: MatDrawer;
  public activities = [];
  public mobileApiSessionAccount: MobileApiUserSession;

  global = config;

  constructor() {
    if (typeof(Storage) !== "undefined") {
      this.loggedInStatus = JSON.parse(localStorage.getItem('loggedIn') ?? 'false');
    } else {
      console.log("Web Storage is not supported by this browser.");
    }
  }

  public invalidateSession() {
    localStorage.removeItem('keycloak');
    localStorage.removeItem('organization-id');
    localStorage.removeItem('patient-third-dose-status');
    this.setIsLoggedIn(false);
    this.isSSOLogin = false;
    delete this.token;
    localStorage.removeItem('keycloakToken');

    delete this.activities;
    delete this.sessionAccount;
    delete this.mobileApiSessionAccount;
  }

  public getSession(): UserSession {
    return this.sessionAccount;
  }

  public setAccountSession(session: UserSession) {
    if (session instanceof UserSession) {
      this.sessionAccount = session;
    } else {
      this.sessionAccount = Object.assign(new UserSession(), session);
    }
    this._sessionAccount.next(this.sessionAccount);
  }

  public getIsLoggedIn() {
    return JSON.parse(localStorage.getItem('loggedIn') || this.loggedInStatus.toString());
  }

  public setIsLoggedIn(status: boolean) {
    if(status !== undefined && typeof status === 'boolean'){
      this.loggedInStatus = status;
      localStorage.setItem('loggedIn', status.toString());
    }
  }

  public get isSSOLogin():boolean {
    return JSON.parse(localStorage.getItem('ssoLogin')) || false;
  }

  public set isSSOLogin(status:boolean) {
    localStorage.setItem('ssoLogin', status.toString());
  }

  public isOrganizationAdmin(): boolean {
    return this.global.PRIVILEGEID.ORGANIZATION_ADMIN === +this.sessionAccount.privilege.id;
  }

  public isOrganizationManager(): boolean {
    return this.global.PRIVILEGEID.ORGANIZATION_MANAGER === +this.sessionAccount.privilege.id;
  }

  public isAnalyticsAdmin(): boolean {
    return this.global.PRIVILEGEID.ANALYTICS_ADMIN === +this.sessionAccount.privilege.id;
  }
  public isSuperAdmin(): boolean {
    return +this.sessionAccount.privilege.id === this.global.PRIVILEGEID.SUPER_ADMIN;
  }

  public isWalgreenAdmin(): boolean {
    const perms = this.sessionAccount.walgreenGroup?.split(',') || [];
    const requiredPerms = ['COVID_ACTIVATION_TEAM', 'CVD_RUNTEAM'];
    return perms.some(perm => requiredPerms.includes(perm));
  }

  public isWalgreenClinicAdmin(){
    const perms = this.sessionAccount.walgreenGroup?.split(',') || [];
    const requiredPerms = ['CP_CLINIC_ADMIN_NP', 'CP_CLINIC_ADMIN_PROD', 'CP_CLINIC_ADMIN_MANU_NP', 'CP_CLINIC_ADMIN_MANU_PROD'];
    return perms.some(perm => requiredPerms.includes(perm));
  }

  set patientCarePlan(value){
    this._patientCarePlan.next(value);
  }
  get patientCarePlan() {
    return this._patientCarePlan.getValue();
  }

  public getMobileApiSession(): MobileApiUserSession {
    return this.mobileApiSessionAccount;
  }

  public setMobileApiAccountSession(session: MobileApiUserSession) {
    if (session instanceof MobileApiUserSession) {
      this.mobileApiSessionAccount = session;
    } else {
      this.mobileApiSessionAccount = Object.assign(new MobileApiUserSession(), session);
    }
    this._mobileSessionAccount.next(this.mobileApiSessionAccount);
  }
}
