import {Injectable, ErrorHandler} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GlobalExceptionHandlerService implements ErrorHandler {

  handleError(error: any) {
    // let router = this.injector.get(Router);
    console.error(error);
    return true;
    // remove comments after development is complete
    // if(error && (error.status == "404" || error.status == "504") ){
    //   router.navigate(['/page-not-found']);
    // }
  }
}
